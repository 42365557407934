import React from 'react';
import video from './../dan-photos/DanPorterVideo.MOV';

const VideoPlayerDan = () => {
  return (
    <div>
      <video src={video} className='dan-video' controls />
    </div>
  );
};

export default VideoPlayerDan;