import React from 'react';

const MaryStory = () => {
    return (
        <div className='mary-story'>
        <br></br>
        <h3>Mary’s story</h3>
        <br></br>
        <p>Mary and Dan enjoyed a rare marriage and loving devotion that produced (2) beautiful children, Daniel, and Margaret. By Dan’s nature, and as his oldest friends can attest, he directly managed all the duties  within the family. Mary was able to concentrate on the children and the family’s spiritual readiness.</p>
        <br></br>
        <p>She shared Dan’s enthusiasm for their new addition to their home. Her mother had just passed away in  November of 2021, and as she prepared the home for Thanksgiving 2021, she had no idea the additional  struggle and suffering just around the corner. The Tuesday before Thanksgiving, she took Dan to the  hospital with flu-like symptoms. Her family missed Thanksgiving, but she was glad to have Dan back  after 10 days in the hospital with the Covid eradicated. Her upcoming birthday of December 8th was  looking to be a joyous occasion. Quite sorrowfully, it proved to be the last day Dan left home to never  return. Mary fought beside Dan during his entire struggle.</p>
        <br></br>
        <p>She never let Dan know the contractor performing the renovation work was taking advantage of their situation. While Mary was at the hospital, the construction was not being performed to code standards. The contractor asked for the draws to complete work that should have been completed on the initial and subsequent draws but was not done. Mary refused to pay him until he performed and repaired the contractually agreed upon work. Their confrontation came to a head as Mary returned to her home from staying in the ICU all day with her unresponsive husband with the contractor standing in her driveway. He demanded immediate pay with another draw on funds, Mary reviewed his work, finding it unchanged and refused him again. He packed his tools and left the premises telling her he would file a lien against their home.</p>
        <br></br>
        <p>He knew Dan’s situation and showed no compassion for a family in need, taking advantage of the situation. As Mary kept all this inside, she devotedly spent her days wiping Dan’s brow, playing uplifting music, and read scripture to her husband, when a blink of his eye or twitch of his nose would make her day. Mary found the website CaringBridge by which she dutifully sent near daily reports to all Dan’s closest friends. They all returned her messages with responses of love and prayers.</p>
        <br></br>
        <p>As the months wore on, and Dan’s condition only worsened, she knew she was facing a nearly insurmountable struggle ahead. The contractor left the house structurally unsound and unsecured from the elements with a real potential of disaster. Like many entrepreneurs, Dan had stuck every extra dollar into his MagnaGrafik business to nurture its growth, thinking that life insurance was not as important as his business would be to the future of his family. He was in his 50’s and in excellent shape and health, younger than his age would suggest.</p>
        <br></br>
        <p>During Dan’s stay in the hospital, the children had only seen their father sparingly and almost entirely looking through a glass window. Covid protocols were wrecking their life in a way beyond what they and their friends could understand. Mary’s house, though it was gradually becoming only her house more each day, was in Covid forbearance which would drop shortly after Dan’s eventual passing. She was facing a substantial monthly mortgage payment with no income as Dan’s MagnaGrafik had sat untouched…in the dying stages right along with its founder. Dan had not yet gotten around to formalizing succession or a will because of his age. Mary discovered the business would end up in probate court upon Dan’s death forcing her to fight to “win it” back through the court system.</p>
        <br></br>
        <p>Showing incredible strength from her faith in God the Father, the Son, and the Holy Spirit, Mary endeavored on. She kept strong and fought the good fight. On that sad day in September 2022, Mary was left as a widow indeed. Her life had become biblical in a way.</p>
        <br></br>
        <p>She and the children focused their anger and pain on getting justice for Dan, as they planned to try to resolve the unsettling medical decisions in court so the doctors and nurses would not make the same decisions for another patient. Meticulous records had been kept, phone conversations recorded, emails traced, and texts captured for nine months. All this evidence was sealed away in banker boxes waiting to be used as appropriate. But, alas, Mary and the children were dealt one more giant blow. Her Cleveland lawyers had thoroughly studied her files agreeing her case was rock solid, but due to Ohio laws regarding recovery due to Covid medical situations that result in death, the case would not be able to move forward.</p>
        <br></br>
        <p>The cost of the case would have been roughly $500,000 and the state of Ohio limits medical malpractice awards to $500,000 leaving nothing left for her family. In her and her children’s grief and anger, they strongly considered fighting anyway…If only to get their family’s story out there. Nonetheless, Mary decided it was time to focus on the bigger fight, the fight for Dan’s and her children’s future.</p>
        <br></br>
        <p>With the help of Margaret and Daniel, Mary has begun to reignite Dan’s business, MagnaGrafik. With outside pro bono consultants helping her understand the business of business, Mary is now trying to see herself as a business leader. They have their share of victories and setbacks, but with the children stepping in to help do the work, she believes deep in her heart they will rebuild it and improve it. This is where you enter the Porter Family LOVE story…</p>
        <br></br>
        <p>November 21st, 2022 would have been Dan's 60th birthday, and for this very reason we waited to launch The Dan Porter Memorial Fund campaign. If you are visiting this website and reading the Porter family’s story, someone has sent it to you in a text, email, or by word of mouth…</p>
        <br></br>
        <p>The Dan Porter Memorial Fund is dedicated to repairing and rebuilding the Porter family home in Mariemont, OH. We plan to not only repair the damage caused by the contractor who walked away and filed a lien, but also, complete the project in a first class manner with bonded and licensed professionals. The cost estimate is $250,000 to finish the house correctly, the way Dan had dreamed and Mary still dreams.</p>
        <br></br>
        <p>Through very generous Moeller teammates of Dan, we are offering a dollar for dollar match up to  $250,000. In other words, some very generous souls have pledged up to $125,000 to double any contribution you make. Let us come together to take the worry of the current structurally flawed house off the worried minds and of Dan’s family so they might focus on the business and Daniel’s and Margaret’s education.</p>
        <br></br>
        <p>We, the friends of Dan and Mary, challenge you to prayerfully consider giving a donation of any size to the Dan Porter Memorial Fund. Additionally, we ask you to consider making your donation a monthly pledge for one year. We know very well the uncertainty of the times, and we understand people’s hesitation to give, but please stand in the shoes of Mary, or Daniel or young Margaret just for a moment pondering on how you would want your family to be treated if they were left behind as widowed or fatherless. Your generosity will bless Dan’s family for a lifetime.</p>
        <br></br>
        <p>But please understand, we do not make this request without also considering passing on two secure  benefits:</p>
        <br></br>
        <ol>
            <li>
                Our fervent prayers of gratitude and thanksgiving.
            </li>
            <br></br>
            <li>
                The Dan Porter Memorial Fund has partnered with the Vulnerable People Project (VPP), a Catholic apostolate, established by Jason Jones to protect and care for the vulnerable. The VPP promotes dignity and inspires solidarity by standing with the vulnerable all over the World. The VPP stands with Mary, Daniel, and Margaret as they work to piece together their lives and build their future. Your donation to the fundraising campaign is a documented tax write-off of the full amount through this partnership with the VPP, a project of H.E.R.O., Inc., a not-for-profit 501(c)(3) organization. The VPP has brought to the table a layer of fiscal accountability as well as a vehicle facilitating donations to the entire campaign.
            </li>
        </ol>
        <br></br>
        <p>NOTE: All recurring donations will be drawn monthly for 12 consecutive months before ending at the anniversary of your gift.</p>
        <br></br>
    </div>
    );
};

export default MaryStory;