import React from 'react';
import video from './../dan-photos/movie.mov';

const VideoPlayer = () => {
  return (
    <div>
      <h2>The Vulnerable People Project</h2>
      <video src={video} className='dan-video' controls />
    </div>
  );
};

export default VideoPlayer;