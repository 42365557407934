import React from 'react';
import video from './../dan-photos/HouseRotVideo.MP4';

const HouseMessVideo = () => {
  return (
    <div>
      <video src={video} className='dan-video' controls />
    </div>
  );
};

export default HouseMessVideo;