import React from 'react';
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';

import family from './../dan-photos/Family2019.png';
import moellerMen from './../dan-photos/1981-moeller-men.png';
import moellerMen2 from './../dan-photos/men-of-Moeller.png';
import danPorsche from './../dan-photos/DanPorsche.JPEG';
import danHospital from './../dan-photos/Victimofbadmedicine.png';
import danStrong from './../dan-photos/DanStrong59.png';
import fam from './../dan-photos/Family.png';
import faith from './../dan-photos/Faith.jpeg';
import chefDan from './../dan-photos/ChefDan.JPG';
import danHospital2 from './../dan-photos/Daninhospitalmargaret.jpeg';
import familyLove from './../dan-photos/FamilyLove.jpeg';
import masterAtWork from './../dan-photos/Masteratwork.JPG';

const images = [
  {
    original: family,
    thumbnail: family,
  },
  {
    original: moellerMen,
    thumbnail: moellerMen,
  },
  {
    original: moellerMen2,
    thumbnail: moellerMen2,
  },
  {
    original: danPorsche,
    thumbnail: danPorsche,
  },
  {
    original: danHospital,
    thumbnail: danHospital,
  },
  {
    original: danStrong,
    thumbnail: danStrong,
  },
  {
    original: fam,
    thumbnail: fam,
  },
  {
    original: faith,
    thumbnail: faith,
  },
  {
    original: chefDan,
    thumbnail: chefDan,
  },
  {
    original: danHospital2,
    thumbnail: danHospital2,
  },
  {
    original: masterAtWork,
    thumbnail: masterAtWork,
  },
  {
    original: familyLove,
    thumbnail: familyLove,
  },
];

const Gallery = () => {
    return (
    <>
    <h2>Gallery</h2>
    <ImageGallery items={images} />;
    </>
    );
  };

export default Gallery;