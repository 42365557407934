import React from 'react';

const DonateButton = () => {
    return (
        <div>
            <a href="https://movietomovement.givingfuel.com/the-dan-porter-memorial-fund">
                <button class='donate-button'>
                    DONATE NOW!
                </button>
            </a>
        </div>
    );
};

export default DonateButton;