import React from 'react';

import danFamily from './../dan-photos/Family2019.png';
import danHospital from './../dan-photos/Victimofbadmedicine.png';

const DanStory = () => {
    return (
        <div className='dan-story'>
        <h3>Dan's Story</h3>
        <br></br>
        <div>
            <div>
                <p>September 20, 2022, was the last day on earth of the wonderful Christian man, Dan Porter. After a long and hard fight, he succumbed to ailments imposed on him by multiple decisions made by the medical-center doctors and caregivers.</p>
                <br></br>
                <p>Dan was a beloved husband, father, and friend to his fellow Cincinnati Moeller football players and  classmates, to his church brothers and sisters, as well as to his customers all over the world. Dan was  known for his determination, hard work, and kind soul. Never one to back away from a tough decision or  action, Dan was widely respected with many coming to him for guidance.</p>
                <br></br>
                <p>Dan garnered worldwide success through his talent as a first-class graphic artist by starting MagnaGrafik,  his vinyl striping, decals, and rock guards company targeted to Porsches. Dan was a such a classic  Porsche enthusiast, he was nicknamed, “Dan Porsche.”</p>
                <br></br>
                <p>MagnaGrafik was doing well breaking its annual revenue records. Dan had owned his home since before  Mary and he were married. Their teenage children, Daniel (18) and Margaret (14), grew up there. The 1990s house needed remodeling. Dan had dreamed about this renovation for years, developing every  detail with his artistic genius. The general contractor had been chosen, and work set in motion the  summer of 2021. The design was beautiful, and the family was counting on Dan’s confident and strong  leadership.</p>
            </div>
        </div>
        <br></br>
        <img src={danFamily} className='image1' />
        <img src={danHospital} className='image1' />
        <br></br>
        <div>
            <div>
            <br></br>
            <p>Unfortunately, by autumn of that same year all of it came crashing down around the family…. Dan was first admitted to the hospital on November 23, 2021, with common Covid symptoms and was  treated with Baricitinib. Due mostly to his dedication to his workout routine and clean diet, Dan was  able to fend off Covid without ventilator support. After ten days of treatment, Dan was released and  walked out of the hospital with a clean bill of health and no follow-up medication. This stay in the  hospital began a series of questionable medical decisions.</p>
            <br></br>
            <p>First, Baricitinib is a strong drug with several deadly side-effects for those with a weakened immune  system, specifically lung issues, the hallmark of Covid. The potential negative impacts of this drug to  those with lung issues are well-documented and well-known in the medical field. Moreover, with its use  to fight Covid, these dangerous outcomes of Baricitinib use became more widely recognized. The  following are five of the top eight thrombotic disorder side effects for this drug:</p>
            <br></br>
            <ul>
                <li>
                    Pulmonary Thromboembolism
                </li>
                <li>
                    Deep Venous Thrombosis
                </li>
                <li>
                    Throat Problems
                </li>
                <li>
                    Herpes Simplex Infection
                </li>
                <li>
                    Esophageal Candidiasis
                </li>
            </ul>
            <br></br>
            </div>
        </div>
        <div>
        <div>
        <p>This treatment continued for 10 days. When Dan was sent home with a prognosis of healed from Covid 19. He was not prescribed any medications, not even blood thinners or steroids to take at home as  follow-up. His body reacted negatively to the lack of a normal steady withdrawal from the powerful  drugs used at the hospital. Unfortunately, the lack of blood thinners left Dan with nothing to fight the  Thrombotic Disorders, which were among the most likely side effects of Baricitinib. Once one  understands the dangers of this drug, it is not difficult to predict the next struggle. Twelve days after leaving the hospital healed from Covid, Dan was rushed back to the emergency room  on December 8, 2021, for pulmonary embolisms. Yes, THE very Thrombotic Disorder rated the most common deadly side effect of Baricitinib AND one of the most common deadly consequences of Covid 19.</p>
        <br></br>
        <p>A few days later, Dan was put on a ventilator, given a paralytic drug to induce a comatose state, and sent to ICU as was typical Covid protocol. These choices were compounded upon the questionable decision to send him home after his first 10-day hospitalization with no thinners or steroids, on top of the decision to ignore the Baricitinib drug side effects as the real potentially deadly threat. Dan had beaten Covid, but due to the decisions of his caregivers, the drug side effects would eventually defeat him.</p>
        <br></br>
        <p>The hospital left Dan on the paralytic for an inordinate amount of time (December 14 to December 30) Once the hospital ended the paralytic use and began to allow Dan to wake up, it took weeks to come out of the comatose state because of the extended time in the drug-induced coma. Unfortunately, Dan never regained full control of his limbs from that point. This inability was likely due to him being on paralytics for 17 days with no movement. He remained in this ICU for weeks until February 13, 2022, when he went into septic shock and became unresponsive. He was taken by ambulance from his current medical center to another one downtown because of his failing condition. It took several days, but the  new ICU unit determined he was suffering from herpes simplex and esophageal candidiasis infections.  Yep, you guessed it, common and dangerous side effects of Baricitinib. Once again, questionable caregiver decisions made in December had started the steady decline of Dan in February of 2022. The first hospital never considered Baricitinib as the cause of Dan’s problems and didn’t alert the new facility upon transfer to the new ICU. This oversight would prove to be critical. Decisions made were now buried in pages and pages of records, which would be found too late.</p>
        <br></br>
        </div>
        </div>
        <div>
            <div>
        <p>Hope dawned in the new ICU where he began breathing on his own and was able to use a talking trach, giving him his first words since December 8, 2021. Once he was clear of this crisis in the new ICU, they released him back to the original ICU on March 18, 2022. Shortly after returning, Dan was released into the Covid care section of the general hospital population, as the failure to identify the true culprit continued.</p>
        <br></br>
        <p>Several weeks into this phase of Dan’s struggle, one nurse practitioner could not understand Dan’s case.  Although it was common for serious acute Covid cases to be held in the hospital for (10) days, she knew  of no other patient released back into society with a clean bill of health to return with a Covid “relapse”. By this time, Dan’s record folder was very thick, and she had to dig deep, but eventually found the Baricitinib prescription. The length of the dosage struck her as peculiar. This drug was experimental, so used only in the worst cases, moreover the side effects were such that many doctors she knew would never prescribe it as the risks significantly outweigh the rewards. She consulted with others on staff, then told Mary that they no longer believed Covid was the problem and from that moment on he would be treated for Baricitinib complications.</p>
        <br></br>
        <p>He was promptly removed from the Covid ward and was set up in the infectious disease ICU of the downtown hospital which had resolved his original septic shock. What Mary hoped to be good news, turned out to be too late to save Dan. Unknown at the time, with Dan’s system being so weak and the infection running amok untreated, candidiasis had set up throughout his body. Dan’s inability to communicate consistently and concisely continued because his esophageal infection would not allow the use of a talking trach. Hence, unable to communicate, his suffering from intense  pain and malaise was kept secret. To add insult to injury, during this move into the infectious disease ward, a (5) millimeter sore was discovered on his lower spine. This tiny sore was to be the insidious monster that took Dan from his family and the rest of us who dearly loved him. The use of a sand bed and wound vac in the ICU was not able to blunt the growing sore. These caregivers determined emergency surgery was necessary as the candidiasis had hit bone. The wound had grown to (4) centimeters wide and (5) centimeters long. To remove all the infection, he left the operating room with a much bigger wound. Although it took until September 20, 2022, for Dan to pass on, by all practicalities, he died in that operating room.</p>
        <br></br>
        </div>
        </div>
    </div>
    );
};

export default DanStory;