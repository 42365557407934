import VideoPlayer from '../components/VideoPlayer';
import VideoPlayerDan from '../components/VideoPlayerDan';
import DonateButton from '../components/DonateButton';
import DanStory from '../components/DanStory';
import MaryStory from '../components/MaryStory';


const Home = () => {
    return (
    <>
    <h2>The Dan Porter Memorial Fund</h2>
    <VideoPlayerDan />
    <DonateButton />
    <DanStory />
    <MaryStory />
    <VideoPlayer />
    <DonateButton />
    </>
    );
  };
  
  export default Home;