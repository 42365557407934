export const menu_items = [
    {
      title: 'Home',
      url: '/',
    },
  
    {
      title: 'Gallery',
      url: '/gallery',
    },

    {
      title: 'House',
      url: '/house',
    },

    {
      title: 'Donate',
      url: 'https://movietomovement.givingfuel.com/the-dan-porter-memorial-fund',
    },
  
  ];