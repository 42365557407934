import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../routes/Home';
import Gallery from '../routes/Gallery';
import House from '../routes/House';
import Layout from './Layout';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="house" element={<House />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;