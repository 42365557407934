import React from 'react';
import HouseMessVideo from '../components/HouseMessVideo.js';
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';

import houseMess1 from './../dan-photos/HouseMess1.jpeg';
import houseMess2 from './../dan-photos/HouseMess2.jpeg';
import houseMess3 from './../dan-photos/HouseMess3.jpeg';
import houseMess4 from './../dan-photos/HouseMess4.jpeg';
import houseMess5 from './../dan-photos/HouseMess5.jpeg';
import houseMess6 from './../dan-photos/HouseMess6.jpeg';
import houseMess7 from './../dan-photos/HouseMess7.jpeg';
import houseMess8 from './../dan-photos/HouseMess8.jpeg';
import houseMess9 from './../dan-photos/HouseMess9.jpeg';
import houseMess10 from './../dan-photos/HouseMess10.jpeg';
import houseMess11 from './../dan-photos/HouseMess11.jpeg';
import houseMess12 from './../dan-photos/HouseMess12.jpeg';
import houseMess13 from './../dan-photos/HouseMess13.jpeg';
import houseMess14 from './../dan-photos/HouseMess14.jpeg';
import houseMess15 from './../dan-photos/HouseMess15.jpeg';
import houseMess16 from './../dan-photos/HouseMess16.jpeg';
import houseMess17 from './../dan-photos/HouseMess17.jpeg';
import houseMess18 from './../dan-photos/HouseMess18.jpeg';
import houseMess19 from './../dan-photos/HouseMess19.jpeg';
import houseMess20 from './../dan-photos/HouseMess20.jpeg';
import houseMess21 from './../dan-photos/HouseMess21.jpeg';
import houseMess22 from './../dan-photos/HouseMess22.jpeg';
import houseMess23 from './../dan-photos/HouseMess23.jpeg';
import houseMess24 from './../dan-photos/HouseMess24.jpeg';
import houseMess25 from './../dan-photos/HouseMess25.jpeg';
import houseMess26 from './../dan-photos/HouseMess26.jpeg';
import houseMess27 from './../dan-photos/HouseMess27.jpeg';
import houseMess28 from './../dan-photos/HouseMess28.jpeg';


const images = [
  {
    original: houseMess1,
    thumbnail: houseMess1,
  },
  {
    original: houseMess2,
    thumbnail: houseMess2,
  },
  {
    original: houseMess3,
    thumbnail: houseMess3,
  },
  {
    original: houseMess4,
    thumbnail: houseMess4,
  },
  {
    original: houseMess5,
    thumbnail: houseMess5,
  },
  {
    original: houseMess6,
    thumbnail: houseMess6,
  },
  {
    original: houseMess7,
    thumbnail: houseMess7,
  },
  {
    original: houseMess8,
    thumbnail: houseMess8,
  },
  {
    original: houseMess9,
    thumbnail: houseMess9,
  },
  {
    original: houseMess10,
    thumbnail: houseMess10,
  },
  {
    original: houseMess11,
    thumbnail: houseMess11,
  },
  {
    original: houseMess12,
    thumbnail: houseMess12,
  },
  {
    original: houseMess13,
    thumbnail: houseMess13,
  },
  {
    original: houseMess14,
    thumbnail: houseMess14,
  },
  {
    original: houseMess15,
    thumbnail: houseMess15,
  },
  {
    original: houseMess16,
    thumbnail: houseMess16,
  },
  {
    original: houseMess17,
    thumbnail: houseMess17,
  },
  {
    original: houseMess18,
    thumbnail: houseMess18,
  },
  {
    original: houseMess19,
    thumbnail: houseMess19,
  },
  {
    original: houseMess20,
    thumbnail: houseMess20,
  },
  {
    original: houseMess21,
    thumbnail: houseMess21,
  },
  {
    original: houseMess22,
    thumbnail: houseMess22,
  },
  {
    original: houseMess23,
    thumbnail: houseMess23,
  },
  {
    original: houseMess24,
    thumbnail: houseMess24,
  },
  {
    original: houseMess25,
    thumbnail: houseMess25,
  },
  {
    original: houseMess26,
    thumbnail: houseMess26,
  },
  {
    original: houseMess27,
    thumbnail: houseMess27,
  },
  {
    original: houseMess28,
    thumbnail: houseMess28,
  },
];

const House = () => {
    return (
    <>
    <h2>House Mess Display</h2>
    <ImageGallery items={images} />
    <HouseMessVideo />
    </>
    );
  };

export default House;